import Home from "./pages/Home"
import './App.css';

function App() {
  return (
    <Home/>
  );
}

export default App;

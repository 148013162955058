import React, { useEffect, useState } from "react"; 
import Tablex from "../../css/Table.module.css";
import Axios from 'axios';
import moment from "moment/moment";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ProgressBar from "@ramonak/react-progress-bar";
import { fontSize } from "@mui/system";

const columns = [
  { id: 'Outlet', 
    label: 'Outlet',
    minWidth: 70,
  },
  { id: 'CusCount', 
    label: 'Customer Count', 
    minWidth: 70,
    align: 'right', 
  },
  {
    id: 'LoyalCus',
    label: 'Loyal Customers',
    minWidth: 70,
    align: 'right',
    
  },
  {
    id: 'CurrentSale',
    label: 'Current Sales',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Target',
    label: 'Today Target',
    minWidth: 70,
    align: 'right',
    
  },
  {
    id: 'LastUpdate',
    label: 'Last Update Time',
    minWidth: 70,
    align: 'right',
    
  },
  {
    id: 'AvgBask',
    label: 'Avg Basket Value',
    minWidth: 70,
    align: 'right',
    
  },
  {
    id: 'Achievement',
    label: 'Achievement',
    minWidth: 220,
    align: 'right',
    
  },
  {
    id: 'Amount',
    label: 'Amount',
    minWidth: 70,
    align: 'right',
    
  },
];

// function createData() {
  
//   return {  };
// }

// const rows = [
//   createData('India', 'IN', 1324171354, 3287263),
//   createData('China', 'CN', 1403500365, 9596961),
//   createData('Italy', 'IT', 60483973, 301340),
//   createData('United States', 'US', 327167434, 9833520),
//   createData('Canada', 'CA', 37602103, 9984670),
//   createData('Australia', 'AU', 25475400, 7692024),
//   createData('Germany', 'DE', 83019200, 357578),
//   createData('Ireland', 'IE', 4857000, 70273),
//   createData('Mexico', 'MX', 126577691, 1972550),
//   createData('Japan', 'JP', 126317000, 377973),
//   createData('France', 'FR', 67022000, 640679),
//   createData('United Kingdom', 'GB', 67545757, 242495),
//   createData('Russia', 'RU', 146793744, 17098246),
//   createData('Nigeria', 'NG', 200962417, 923768),
//   createData('Brazil', 'BR', 210147125, 8515767),
// ];

export default function StickyHeadTable() {

    const[data,setdata]=useState([]);
    const[date,setDate]=useState([]);

    var TodayD = moment(new Date().toLocaleString() + '').format('YYYY-MM-DD');
    
    useEffect(()=>{
    
      var dateDSumm = moment(new Date().toLocaleString() + '').format('YYYY-MM-DD');
      getdata(dateDSumm);
    },[]);

    // const getdata = (date) =>{
    //     try {
    //         Axios.get("/PosStart?date="+date).then(
    //             (response)=>{
    //                 console.log(response.data);
    //                 setdata(response.data);
    //                 console.log(data)
    //         }
    //         );
    //     } catch (error) {
    //         console.log(error);
    //     } 
        
    // };

    function totalAmount(items) {
      return items.map(({ Amount }) => Amount).reduce((sum, i) => sum + i, 0);
    }

    const total = totalAmount(data);

    const getdata = async(dateDSummm)=>{
      // const api = await fetch(
      //   `/PosStart?date=`+dateDSummm
      // );
      // const data = await api.json();
      const data = [
        {
            "Outlet": "Kottawa",
            "CusCount": 108,
            "LoyalCus": 29,
            "CurrentSale": 1702.33,
            "Target": 6712.77,
            "LastUpdate": "2023-01-02",
            "AvgBask": 1949.90,
            "Achievement": 60,
            "Amount": 1523.36,         
        },
        {
          "Outlet": "Mirihana",
          "CusCount": 118,
          "LoyalCus": 24,
          "CurrentSale": 1502.33,
          "Target": 6722.77,
          "LastUpdate": "2023-01-05",
          "AvgBask": 1449.90,
          "Achievement": 70,
          "Amount": 1589.36,         
      },
      {
        "Outlet": "Dehiwala",
        "CusCount": 108,
        "LoyalCus": 29,
        "CurrentSale": 1702.33,
        "Target": 6712.77,
        "LastUpdate": "2023-01-02",
        "AvgBask": 1949.90,
        "Achievement": 40,
        "Amount": 1123.36,         
    },
    {
      "Outlet": "Matara",
      "CusCount": 118,
      "LoyalCus": 24,
      "CurrentSale": 1502.33,
      "Target": 6722.77,
      "LastUpdate": "2023-01-05",
      "AvgBask": 1449.90,
      "Achievement": 90,
      "Amount": 1989.36,         
  }
    ]
      //delete data[0].itemName;
      //console.log(data[0])
      // setdata(Object.values(data[0]))
      // setdata2(Object.keys(data[0]))   
      setdata((data))  
      console.log(data)       
  };

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  return (
    <div>
    {/* <div className= {Tablex.calan}>
            <div className={Tablex.head}>
                <input type="date" onChange={e=>setDate(e.target.value)} defaultValue={TodayD}/>
            </div>
            
            <div>
                <button onClick={()=>getdata(date)}>Refresh</button>
            </div>
    </div> */}
    
    <div className={Tablex.table}>
    <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%'}} >
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table" >
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontSize: "15px", fontWeight:"bold", backgroundColor: "black", color:"white"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.Outlet}>
                        <TableCell align={row.align}>                          
                        {row.Outlet}
                        </TableCell>
                        <TableCell align="center">{row.CusCount}</TableCell>
                        <TableCell align="center">{row.LoyalCus}</TableCell>
                        <TableCell align="center">{row.CurrentSale}</TableCell>
                        <TableCell align="center">{row.Target}</TableCell>
                        <TableCell align="center">{row.LastUpdate}</TableCell>
                        <TableCell align="center">{row.AvgBask}</TableCell>
                        <TableCell align="right"><ProgressBar completed={row.Achievement} className={Tablex.wrapper} barContainerClassName={Tablex.container} labelClassName={Tablex.label}/></TableCell>
                        <TableCell align="right">{row.Amount}</TableCell>
                  </TableRow>                                             
              ))}
               <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={7}>Subtotal</TableCell>
                  <TableCell align="right">{total}</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell align="right">gsg</TableCell>
                  <TableCell align="right">34</TableCell>
              </TableRow>
              <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">64634</TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      {/* <MaterialTable         
            options={{
                exportButton: true,
            }}
        /> */}
    </Paper>
    
            
      
    </div>
    </div>
  );
}
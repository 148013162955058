import React from 'react'
import Chart from 'react-apexcharts'
import '../../css/PieChart.css'
import { useEffect, useState } from 'react'
import moment from "moment/moment";

const PieChart = () => {

  const[dataDSumm,setDSumm] = useState([]);

  useEffect(()=>{
    const dateDSumm = moment(new Date().toLocaleString() + '').format('DD/MM/YYYY');     
    getdata(dateDSumm);  
  },[]);


  //Total sales
  const getdata = async(date)=>{
    try{
      const api = await fetch(
        `/PortalDWiseSalesAll?date=`+date
      );
      const data = await api.json();
      setDSumm((data)); 
      console.log(data) 
    }
    catch (error) {
      console.log(error);
   }
             
    };


    const data = {
        series: dataDSumm.map(x=>x.net),
        // [987456.89,234456.12,123456.89,234567.98,34567.34]
        // dataDSumm.map(x=>x.net),
            option: {
              chart: {
                width: 380,
                type: 'donut',
              },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270
                }
              },
              dataLabels: {
                enabled: true
              },
              fill: {
                type: 'gradient',
              },
              // legend: {
              //   formatter: function(val, opts) {
              //     return  val +"- " + opts.w.globals.series[opts.seriesIndex]
              //   },
              //   customLegendItems: dataDSumm.map(x=>x.locaName),
              //   fontSize: '10px',
                
              // },
              // title: {
              //   text: ''
              // },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            },
    };

  return (
    <div className="PieChart">
        <Chart series={data.series} options={data.option}  type='donut' height={380} />
    </div>
  )
}

export default PieChart
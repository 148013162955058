import React from 'react'
import BarChart from '../chart/BarChart'
import '../../css/MainDash.css';


const DashBar = () => {
  return (
    
    <div className="MainDashChart">
        <BarChart/>      
    </div>  
    
  )
}

export default DashBar
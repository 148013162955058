import React from 'react'
import Tabletest from '../chart/Tabletest';
import '../../css/MainDash.css';

const Start = () => {
  return (
    <div className='MainDash'>
        <Tabletest/>
    </div>   
  )
}

export default Start
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import '../../css/BarChart.css'
import moment from "moment/moment";

function BarChart(){

const[dataDSumm,setDSumm] = useState([]);
const[date,setDate]=useState([]);

var TodayD = moment(new Date().toLocaleString() + '').format('YYYY-MM-DD');
   
  useEffect(()=>{
   
    var dateDSumm = moment(new Date().toLocaleString() + '').format('YYYY-MM-DD');
    getdata(dateDSumm);
  },[]);

const getdata = async(dateDSummm)=>{
    const api = await fetch(
      `/PortalDailySummary?date=`+moment(dateDSummm).format('DD/MM/YYYY')
    );
    const data = await api.json();
  //   const data = [
  //     {
  //         "itemName": "12/12/2022",
  //         "thalawathugoda": 118452.802,
  //         "athurugiriya": 245794.937,
  //         "kalaniya": 150272.334,
  //         "palmadulla": 67223.707,
  //         "wattala": 83364.092,
  //         "rathnapura": 144966.904,
  //         "palawatta": 0.000,
  //         "arangala": 158920.360,
  //         "attidiya": 151642.572,
  //         "kochchikade": 67082.337,
  //         "panadura": 120906.924,
  //         "godagama": 65791.231,
  //         "piliyandala": 133980.375,
  //         "nalluruwa": 114263.845,
  //         "gorakana": 73088.315,
  //         "minuwangoda": 146356.234,
  //         "kadawatha": 224796.391,
  //         "akuregoda": 173954.649,
  //         "stores": 12208.715,
  //         "kottawapre": 1031298.360,
  //         "kimbulawela": 56916.247,
  //         "kaluthara": 78659.395,
  //         "avissawella": 95483.226,
  //         "crescat": 187931.874,
  //         "kotte": 89721.555,
  //         "hokandara": 90796.404,
  //         "ambalangoda": 0.000,
  //         "mathugama": 44365.387,
  //         "battaramulla": 158748.531,
  //         "godakawela": 178951.934,
  //         "athurupre": 552117.703,
  //         "duplication": 187151.452,
  //         "seeduwa": 41656.281,
  //         "mirihanapre": 455157.022,
  //         "kaduwela": 33548.517,
  //         "koswatta": 0.000
  //     }
  // ]
    //delete data[0].itemName;
    //console.log(data[0])
    // setdata(Object.values(data[0]))
    // setdata2(Object.keys(data[0]))   
    setDSumm((data))  
    console.log(data)       
};


const data = {

        series: [{
          data: dataDSumm.map(y => y.net)            
          }],
          option: {
            chart: {
              type: 'bar',
              height: 350,
                           
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
              }
            },
            dataLabels: {
              enabled: true
            },
            xaxis: {             
              categories: dataDSumm.map(x => x.locaName)            
            }
        }
    };

return (
      <div className="BarChart">
          <div className= "calan">
              <div className= "head">
                  <input type="date" onChange={e=>setDate(e.target.value)} defaultValue={TodayD}/>
              </div>
              
              <div>
                  <button onClick={()=>getdata(date)}>Refresh</button>
              </div>
           </div>                  
           <Chart series={data.series} options={data.option}  type='bar' height={350} /> 
                                           
      </div>
    )

}

export default BarChart;
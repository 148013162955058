import React, { useEffect, useState } from "react"; 
import Tabletest from "../../css/Tabletest.module.css";
import Axios from 'axios';
import moment from "moment/moment";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'postDate', 
    label: 'Post Date',
    minWidth: 170,
  },
  { id: 'startDateTime', 
    label: 'Start Date Time', 
    minWidth: 100 
  },
  {
    id: 'userName',
    label: 'User Name',
    minWidth: 170,
    align: 'right',
    
  },
  {
    id: 'dayEndTime',
    label: 'Day End Time',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'loca',
    label: 'Loca',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'insertdate',
    label: 'Insert Date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData() {
  
  return {  };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function StickyHeadTable() {

    const[data,setdata]=useState([]);
    const[date,setDate]=useState([]);

    var TodayD = moment(new Date().toLocaleString() + '').format('YYYY-MM-DD');
    
    useEffect(()=>{
    
      var dateDSumm = moment(new Date().toLocaleString() + '').format('YYYY-MM-DD');
      getdata(dateDSumm);
    },[]);

    const getdata = (date) =>{
        try {
            Axios.get("/PosStart?date="+date).then(
                (response)=>{
                    console.log(response.data);
                    setdata(response.data);
            }
            );
        } catch (error) {
            console.log(error);
        } 
        
    };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
    <div className= {Tabletest.calan}>
            <div className={Tabletest.head}>
                <input type="date" onChange={e=>setDate(e.target.value)} defaultValue={TodayD}/>
            </div>
            
            <div>
                <button onClick={()=>getdata(date)}>Refresh</button>
            </div>
    </div>
    <div className={Tabletest.table}>
    <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%'}} >
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.idNo}>
                        <TableCell key={row.id} align={row.align}>                          
                        {moment(row.postDate).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell align="right">{moment(row.startDateTime).format('LT')}</TableCell>
                        <TableCell align="right">{row.userName}</TableCell>
                        <TableCell align="right">{row.dayEndTime}</TableCell>
                        <TableCell align="right">{row.loca}</TableCell>
                        <TableCell align="right">{moment(row.insertdate).format('YYYY-MM-DD')}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
    </div>
  );
}

//Sidebar imports
import{
    UilEstate,
    UilClipboardAlt,
    UilUsersAlt,
    UilPackage,
    UilUsdSquare,
    
} from "@iconscout/react-unicons";

import img1 from '../imgs/Boss.png'
import img2 from '../imgs/Boss1.png'
import img3 from '../imgs/boss2.png'

//Sidebar Data
 export const SidebarData = [
    {
        id: "/",
        icon: UilEstate,
        heading: "Dashboard",
    },
    {
        id: "/start",
        icon: UilClipboardAlt,
        heading: "Start",
        
    },
    {
        
        id: "/customers",
        icon: UilUsersAlt,
        heading: "Customers",
    },
    {
        id: "/",
        icon: UilPackage,
        heading:" Log"
    },
        
    
];


//Card Data
export const CardsData =[

    {
        id :1,
        title: "Premium Outlet Sales",
        color:{
            backGround:"#65d461",
            boxShadow: "0px 10px 20px 0px #e0c6f5",
        },
        barValue:0,
        value: "36",
        png: UilUsdSquare,
        series:[
            {
                name: "Sales",
                data: [23,56],
            },
        ],
    },

    {
        id :2,
        title: "Revenue",
        color:{
            backGround: "#bb67ff",
            boxShadow: "0px 10px 20px 0px #e0c6f5",
        },
        barValue:0,
        value: "25,970",
        png: UilUsdSquare,
        series:[
            {
                name: "Sales",
                data: [31,50,80],
            },
        ],
    },

    {
        id:3,
        title: "Expenses",
        color:{
            backGround:"#fc8479",
            boxShadow: "0px 10px 20px 0px #e0c6f5",
        },
        barValue:0,
        value: "25,970",
        png: UilUsdSquare,
        series:[
            {
                name: "Sales",
                data: [31, 40, 28, 51, 42, 109, 100, 102, 210,1200],
            },
        ],
    },
];

//RightSidebar Data
export const UpdatesData = [
    { 
        
        img: img1,
        name: "Andrew Thomas",
        noti: " has ordered apple smart watch 2500mh battery.",
        time: "25 seconds ago",
    },
    {
        
        img: img2,
        name: "james Bond",
        noti: " has received Samsung gadget for charging battery.",
        time: "30 minutes ago",
    },
    {
        
        img: img3,
        name: "Iron Man",
        noti: " has ordered apple smart watch, samsung Gear 2500mh battery.",
        time: "2 hours ago",
    },

]


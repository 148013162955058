import React from 'react'
import Table from '../chart/Table';
import '../../css/MainDash.css';

function Customer() {
  return (
    <div className='MainDash'>
        <Table/>
    </div> 
  )
}

export default Customer
import React from 'react';
import '../../css/Cards.css';
import   {CardsData}  from '../../data/data';
import Card from '../cards/Card';
import { useEffect, useState } from 'react'
import moment from "moment/moment";


const Cards = () => {

  //Total Sales
  const[dataDSumm,setDSumm] = useState([]);
  const[totalNet, setTotalNet] = useState(0);

  ///Total loyalty Customer Registration
  const[dataPortalCus,setPortalCus] = useState([]);
  const[totalCus, setTotalCus] = useState(0);

  //Today Customer Registration
  const[dataPortalCusRegi,setPortalCusRegi] = useState([]);
  const[totalCusRegi, setTotalCusRegi] = useState(0);
     
  //get today date and function calling
  useEffect(()=>{
    const dateDSumm = moment(new Date().toLocaleString() + '').format('DD/MM/YYYY');     
    getdata(dateDSumm); 
    PortalCus(); 
    PortalCusRegi(dateDSumm); 
  },[]);


    //Total sales
    const getdata = async(date)=>{
    const api = await fetch(
      `/PortalDWiseSalesPremi?date=`+date
    );
    const data = await api.json();
    setDSumm((data));           
    };
    //Total loyalty Customer Registration
    const PortalCus = async()=>{
      const api = await fetch(
        `/PortalCustomer`
      );
      const data = await api.json();
      setPortalCus((data));           
    };
    //Today Customer Registration
    const PortalCusRegi = async(date)=>{
      const api = await fetch(
        `/PortalCustomerRegi?date=`+date
      );
      const data = await api.json();
      setPortalCusRegi((data));           
    };


    //Total sales
    useEffect(()=>{
      const total = dataDSumm.reduce((acc, row)=> acc + row.net, 0);
      setTotalNet(total); 
    },[dataDSumm]);
    //Total loyalty Customer Registration
    useEffect(()=>{
      const total = dataPortalCus.reduce((acc, row)=> acc + row.totalCus, 0);
      setTotalCus(total); 
    },[dataPortalCus]);
    //Today Customer Registration
    useEffect(()=>{
      const total = dataPortalCusRegi.reduce((acc, row)=> acc + row.totalCus, 0);
      setTotalCusRegi(total); 
    },[dataPortalCusRegi]);


  return (
    <div className="Cards">
    
        {CardsData.map((card,id)=>{
            return(
                <div className="parentContainer" key={card.title}>
                 
                    <Card                         
                     title={(id===1)?"Total L Customers" : (id===2)?"Today Registration":"Premium Outlet Sales"}
                     color={card.color}
                     barValue={(id===1)?"50" : (id===2)?"80":"30"}
                     value = {(id===1)? totalCus : (id===2)? totalCusRegi: totalNet}
                     png={card.png}
                     series= {(id===1)?[{ name: "1",data: dataPortalCus.map(y=>y.totalCus)}]:(id===2)?[{ name: "2",data: dataPortalCusRegi.map(w=>w.totalCus)}]:[{ name: "3",data: dataDSumm.map(x=>x.net)}]}                   
                     xdatay= {(id===1)?dataPortalCus.map(y=>y.locaName):
                     (id===2)?dataPortalCusRegi.map(w=>w.locaName):dataDSumm.map(x=>x.locaName)}                                  
                    />
                    
                </div>
            )
        })}
       
    </div>
  )
}

export default Cards

import MainDash from '../components/maindash/MainDash';
import Sidebar from '../components/sidebar/Sidebar';
import '../css/Home.css';
import{ BrowserRouter, Routes, Route} from 'react-router-dom';
//import RightSide from '../components/rightside/RightSide';
import Start from '../components/maindash/Start';
import Customer from '../components/maindash/Customer';

function Home() {
  return (
    <div className="Home">
      <div className="HomeGlass">       
        <BrowserRouter>    
                <Sidebar/>               
                <Routes>                
                  <Route path='/' element={<MainDash/>} />      
                  <Route path='/start' element={<Start/>} />
                  <Route path='/customers' element={<Customer/>} />                                 
                </Routes> 
                {/* <RightSide/>                          */}
        </BrowserRouter>        
      </div>
    </div>
  );
}

export default Home;
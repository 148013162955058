import React from 'react'
import '../../css/MainDash.css';
import Cards from '../cards/Cards';
import DashBar from './DashBar';
import PieChart from '../chart/PieChart';


const MainDash = () => {
  return (
    <div className="MainDash">      
            <Cards/> 
            <PieChart/> 
            <DashBar/>             
    </div>
  )
}

export default MainDash